/*기본 셋팅*/
* {box-sizing: border-box;}
html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, button, input, textarea, p, select, blockquote, th, td, img, a {margin: 0;padding: 0;font-weight: 300;color: #000;font-size: 1em;word-break: keep-all;word-wrap: break-word;}
html, body {font-size: 16px;width: 100%;height: 100% !important;overflow-x: hidden;overscroll-behavior: contain;}
body.active {overflow-y: hidden;}
li {list-style: none;}
a {text-decoration: none;color: #000;}
a:hover {text-decoration: none;}
a:active {text-decoration: none;}
table {width: 100%;border-collapse: collapse;border-spacing: 0;}
img {border: 0;}
button {background: transparent;border: 0;cursor: pointer;outline: 0;}
input, select, textarea {border: 1px solid #aaa;-webkit-appearance: none;-webkit-border-radius: 0;outline: none;}
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {-webkit-appearance: none;margin: 0;}

/*left menu*/
.left-menu-box{display: flex;flex-direction: column;width: 260px;overflow: auto;height: 100vh;position: fixed;left: 0;background: #EEF2F5;border-right: 1px solid #f0f0f0;}
.left-menu-box a{color: #959BA0 !important;}
.left-menu-box .ant-menu-item-selected{color: #000000 !important;}
.left-menu-box .ant-menu-sub.ant-menu-inline{background: #EEF2F5;}
.left-menu-box .ant-menu-inline > .ant-menu-item, .ant-menu-submenu-title{height: 55px !important;}
.left-menu-box .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{background-color: #EEF2F5;}
.left-menu-box .ant-menu-item-selected a, .ant-menu-item-selected a:hover{color: #1795D3 !important;font-size: 16px;}
.left-menu-box .ant-menu-light .ant-menu-item-active a{color: #1795D3 !important;font-size: 16px;}
.left-menu-box .ant-menu-light .ant-menu-item::after{color: #000000;border-right: 0;height: 55px;}

.left-menu-box .logo-box{width: 100%;padding: 25px;display: flex;justify-content: center;}
.left-menu-box .logo-box .logo{width: 175px;}

.left-menu-box .item-box{background: #EEF2F5;}
.left-menu-box .item-box .sub-menu{font-size: 14px;color: #1795D3 !important}

.left-menu-box .profile-box{width: 100%;padding: 18px;background: #EEF2F5;height: fit-content;position: absolute;bottom: 0;}
.left-menu-box .profile-box .title{font-weight: 400;font-size: 14px}

.left-menu-box .nav-text{font-size: 16px;font-weight: bolder;color: #1795D3}

/*margin*/
.ml-10{margin-left: 10px}

/*menu*/
.menu-box{display: flex;}
.menu-box > div{padding: 15px 12px;width: 50%;font-size: 16px;box-shadow: none;text-align: center;font-weight: 400;}
.menu-selected{color: #000000; border-bottom: 4px solid #1795D3;}
.menu-none-selected{color:#C0C4CC; border-bottom: 4px solid #C0C4CC;}
.menu-none{width: 100% !important; border-bottom: 4px solid #C0C4CC}

.justify-start{justify-content: flex-start !important;}

.ant-pagination-options {display: none;}

.text-center{text-align: center;justify-content: center;}
.text-right{text-align: right !important;justify-content: flex-end !important;}

.margin-top-5{margin-top: 5px}
.margin-top-10{margin-top: 10px}
.margin-top-50{margin-top: 50px}
.right{margin-left: auto;}

.flex-center{display: flex;gap: 5px;align-items: center; justify-content: center}

.float-right{float: right;}
.justify-end{display: flex; width: 100%; justify-content: flex-end}

.year-button{display: flex;width: 151px;padding: 7px 12px;align-items: flex-start;gap: 10px;border-radius: 4px;border: 1px solid #E4E7ED;background: #FFF;}
.year-button input{text-align: center;border: none;}
.year-button svg{margin-top: 10px;}

.detailBox{margin: 32px;}
.round-button{border-radius: 4px;background: #1795D3;}

.ant-layout-content{min-height: revert;}

/*home*/
.statisticBox{padding: 20px;}

.ant-statistic{display: flex;background-color: #FAFAFA;padding: 22px 20px;border-radius: 10px;gap: 14px;flex-direction: column;align-items: flex-start;flex: 1 0 0;width: 100%;}
.ant-statistic-content, .ant-statistic-content-value{width: 100%;}
.ant-statistic-title{font-size: 18px;font-weight: 400;color: #262626;line-height: 22px;}
.ant-statistic-content-value-int{font-weight: 500;font-size: 26px;line-height: 22px;color: #1795D3;}

.ant-statistic-sub{display: flex;flex-direction: column;align-items: flex-start;gap: 6px;align-self: stretch;margin-top: 16px;}
.ant-statistic-sub-item{display: flex;align-items: flex-start;gap: 14px;align-self: stretch;font-size: 13px;font-weight: 400;line-height: 18px;}
.ant-statistic-sub-item-title{flex: 1 0 0;color: #262626;}
.ant-statistic-sub-item-int{color: #1795D3;text-align: right;}

/*file*/
.file-contents{width: 100%;overflow: hidden;}

/*select*/
.ant-select-arrow{top: 43%;}
.ant-select-selection-item {top: 10%}
.ant-select-selector {position: relative !important;display: inline-block !important;width: 100% !important;height: 40px !important;min-width: 0 !important;line-height: 1.5715 !important;border-radius: 8px !important;transition: all 0.3s !important;vertical-align: middle;}
.full-select{width: 100%;}

/*search*/
.search-label{font-weight: 400; font-size: 14px; color: #606266;margin-left: 30px;margin-top:10px;}
.search-area{width: 100%;min-height: 50px; margin-top:15px; margin-bottom: 15px; white-space : nowrap;display: flex;justify-content: space-between;align-items: center;}
.search-area .sub-title{font-weight: 500;font-size: 18px; color: #606266; margin-left: 20px}
.search-input{min-width: 30%; height: 40px}
.search-date{min-width: 110px;height: 40px;margin-left: 10px;border-radius: 8px;}
.search-select{width: 30%; margin-left: 10px}
.search-input-button{background: #F9FBFC; color: #606266; height: 40px; border-radius: 0px 8px 8px 0px;margin-right: 5px;}

/*toast*/
.ant-message-notice-content {background-color: #000000 !important;color: #ffffff !important;border-radius: 8px;}
.ant-message-success {color: #FFF;}
.ant-message-warning {color: #FFF;}
.ant-message-error {color: #FFF;}

/*table*/
.ant-table{margin-top: 20px;}
.ant-table-thead > tr > th{color: #909399;font-size: 14px;font-weight: 500;background: white;}
.ant-table-cell{color: #606266;font-size: 14px;font-weight: 400;background: white;}
.table-plus-button{display: flex;width: 100%;height: 40px;align-items: center;background: #DCE2E7;justify-content: center;}
.table-plus-half-button{display: flex;width: 50%;height: 40px;align-items: center;background: #DCE2E7;justify-content: center;}
.table-plus-small-button{display: flex;width: 16%;height: 40px;align-items: center;background: #DCE2E7;justify-content: center;}

.table-small-input{width: 100px; margin-right: 5px}

.withholding-box{display: flex; width: 100%; height: 80px;}
.withholding-price-box{display: flex; width: 100%; height: 50px;}
.withholding-sub-box{display: flex; flex-direction: column; width: 500px; border-right: 1px solid #D9D9D9; border-bottom: 1px solid #D9D9D9}
.withholding-sub-box .large-box{height: 80px;}
.withholding-sub-box .small-box{height: 50px;}
.withholding-title{height: 60px;display: flex;width: 100%;color: #909399;font-weight: 500;font-size: 14px;border-bottom: 1px solid #D9D9D9}
.withholding-left-contents-box{height: 60px;display: flex;width: 100%;min-width: 360px;color: #909399; flex-direction: row; align-items: center; font-weight: 400; font-size: 19px}
.withholding-left-contents{width: 150px;color: #909399;font-weight: 400;font-size: 14px;display: flex;align-items: center}
.withholding-price-title{height: 80px;display: flex;width: 100%;color: #909399;font-weight: 400;font-size: 14px;border-bottom: 1px solid #D9D9D9;align-items: center;justify-content: center}
.withholding-price-contents{height: 50px;display: flex;width: 100%;color: #909399;; font-weight: 400; font-size: 14px; border-bottom: 1px solid #D9D9D9; align-items: center; justify-content: center; overflow-x: auto; overflow-y: hidden}
.withholding-price-area{width: 100%; overflow: auto; min-width: 90%; white-space: nowrap;}
/*button*/
.button{border-radius: 4px;margin-left: 6px;margin-right: 6px;height: 40px;}
.button.small{font-size: 14px;padding: 10px;}
.button.full{width: 95%;height: 22px;font-size: 12px;}
.green-button{background-color: #41A56A;color: #FFF;}
.primary-button{background-color: #1795D3;color: #FFF;border-color: #1795D3;}
.primary-ghost-button{border: 1px solid #1795D3;color: #1795D3;border-color: #1795D3;}
.cancel-button{background-color: #8F8F8F;color: #FFF;border-color: #8F8F8F;}
.danger-button{background-color: #F56C6C;color: #FFF;border-color: #F56C6C}
.deep-blue-button{background-color: #09519A;color: #FFF;border-color: #09519A;}
.plus-button{background: #F5F7FA; height: 40px; width: 45px; border-radius: 0 8px 8px 0; display: flex; align-items: center; justify-content: center;cursor: pointer;}
.minus-button{background: #F5F7FA; height: 40px; width: 45px; border-radius: 8px 0 0 8px; display: flex; align-items: center; justify-content: center;cursor: pointer;}
.plus-minus-input{margin-left: 0px !important;}
.delete-button{color: #606266;margin-left: 16px;}

/*side*/
.ant-menu-submenu-title > .ant-menu-title-content{font-size: 16px;font-weight: bolder;color: #959BA0 !important;}

/*text*/
.bolder{font-weight: bolder;}
.bold-700{font-weight: 700 !important;}
.blue-text{color: #409EFF;}

/*form*/
.title-area{display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-top: 25px;padding-left: 1.25em;}
.title{font-weight: 400; font-size: 18px;}
.title-button-box{display: flex; align-items: center;margin-left: 20px;}
.title-button-area{display: flex; flex-direction: column; text-align: right;margin-right: 20px;}

.contents-box{width: 100%;display: flex;gap: 20px;flex-direction: column;position: relative;padding: 1.25rem;min-width: 0;word-wrap: break-word;background-color: #fff;background-clip: border-box;border-radius: 0.25rem;min-height: 1px;margin-top: 20px;}
.contents{font-size: 15px;font-weight: 400;color: #606266;margin-right: 20px;width: 30%; display: flex; align-items: center}
.contents-non-width{font-size: 15px;font-weight: 400;color: #606266;margin-right: 20px;display: flex; align-items: center; margin-left: 5px}
.contents-label{margin-right: 0px;}
.contents-blue{font-weight: 400;color: #1795D3;}
.contents-sub-box{margin-bottom: 40px;}
.contents-item{display: flex; flex: 1; align-items: center;margin-top: 20px;}
.contents-item-box{display: flex; padding: 0 20px 0 20px; white-space: nowrap}

.date-text{color: #303133; font-weight: 400; font-size: 14px}

.contents-col2-box{display: flex;justify-content: space-between;}
.col2{width: 50%;padding: 10px;}

/*popconfirm*/
.popconfirm-contents{font-weight: 500}
.popconfirm-sub-contents{font-weight: 500; color: #606266}

/*modal*/
.modal-contents-box{display: flex; text-align: center; flex-direction: column; align-items: center}
.modal-contents-div{display: flex; text-align: center; flex-direction: column; align-items: center}
.modal-contents-icon{display: flex; width: 100%; justify-content: center; margin-bottom: 10px}
.modal-contents{font-weight: 500; font-size: 15px; display:flex; justify-content:center}
.modal-datePicker{width: 200px; height: 40px; margin-top: 10px}

.icon{width: 20px; height: 20px; margin-right: 5px}

.ant-col-12, .ant-col-24, .ant-col-8{display: flex;}
.ant-col-24{padding-top: 15px;}
.ant-col-12:last-child{padding-left: 20px;}
.ant-col-8:not(:first-child){padding-left: 10px;}

.listMemo{display: inline-block;max-width: 9ch;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}

/*input*/
.ant-input{margin-left: 4px;height: 40px;}
.ant-input-suffix{color: #909399;font-size: 12px;}

/*.ant-picker{width: 100%;}*/
.ant-col-12 .label-input.large-text{width: 72%;}
.ant-col-12 .label-input{width: 83%;}
.ant-col-24 .label-input{width: 92%;}

.contents-input-before-label{ display: flex;align-items: center;justify-content: center;height: 40px;width: 30%;background: #F5F7FA;color: #606266;border: 1px solid #DCDFE6;border-radius: 8px 0px 0px 8px;font-weight: 300}
.contents-input-before{border: 1px solid #DCDFE6;width: 70%;border-radius: 0px 8px 8px 0px;margin-left: 0;}

/*checkbox*/
.ant-checkbox-wrapper{margin-right: 10px;}
.checkbox-box{display: flex;width: 100%;flex-direction: column;align-items: flex-start;gap: 20px;}
.checkbox-item{display: flex;align-items: flex-start;align-self: stretch;}
.round-checkbox .ant-checkbox-inner{border-radius: 50%;}

/*label*/
.label-box{display: flex;}
.full-label{width: 10%;}
.green-label{background: #D0F9F3;color: #1BC5AA;border: 2px solid #8AEFDF;width: 50px;height: 30px;align-items: center;display: flex;justify-content: center;margin-right: 10px;border-radius: 5px;}
.red-label{background: #FDECEC;color: #F56C6C;border: 2px solid #FAD8D8;width: 50px;height: 30px;align-items: center;display: flex;justify-content: center;border-radius: 5px;}

/*pagination*/
.pagination{width: 100%; margin: 30px 0 0 auto; display: flex; align-items: center; justify-content: flex-end}

.company-select-box{display: flex;height: 500px;justify-content: space-between;padding: 0 30px;white-space: nowrap;width: 100%;}
.company-select-box .button-box{align-items: center;display: flex;height: 100%;justify-content: space-between;margin: 0 20px;white-space: nowrap;width: 200px;}
.company-select-box-item{display: flex;flex-direction: column;height: 100%;min-width: 300px;width: 40%;}
.company-select-box-item .header{align-items: center;background: #f5f7fa;border: 2px solid #ebeef5;display: flex;height: 45px;justify-content: space-between;padding: 20px;width: 100%;}
.company-select-box-item .header div{display: flex;}
.company-select-box-item .contents{border: 1px solid #ebeef5;display: flex;flex-direction: column;height: 100%;padding: 10px 10px 0;width: 100%;}
.company-select-box-item .contents .search{border: 1px solid #ebeef5;border-radius: 20px;height: 40px;margin-bottom: 15px;margin-top: 5px;width: 100%;}
.company-select-box-item .contents .company{align-items: center;display: flex;height: 40px;width: 100%;}

/*margin, padding*/
.p-0{padding: 0}
.p-20{padding: 20px;}
.ml-10{margin-left: 10px;}

/*comment*/
.commentBox{color: #606266;}

.bottomBorder{border-bottom: 0.5px solid #EAEAEC;}

/*salary*/
.salary-table-box{overflow: auto;width: 100%;height: 100%;display: flex;}
.salary-table {width: 100%; min-width: 600px; box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.10);z-index: 10; white-space: nowrap}
.salary-table .ant-table-thead {height: 75px;}
.salary-table .ant-table-tbody {height: 59px;}
.salary-second-table {width: 100%; min-width: 600px; background: #F9FBFC; white-space: nowrap}
.salary-second-table .ant-table-tbody > tr > td {background-color: #F9FBFC;text-align: center;}
.salary-second-table .ant-table-thead > tr > th, .salary-second-table .ant-table-thead > tr > th{background: #F9FBFC;}
.salary-second-table .ant-table-container table > thead > tr:first-child th{border-top-left-radius: 3px;padding: 2px;height: 75px;text-align: center;}


.salary-table-input{height: 22px;width: 100px;background: transparent;border: 0}

.table-progress-box{display: flex;font-size: 23px;justify-content: space-around;width: fit-content;border: 2px solid #FF6533;padding: 7px;border-radius: 8px;height: 40px;align-items: center;gap: 10px;margin-left: 10px;}
.table-progress-item{font-size: 12px;font-weight: 500;color: #FF6533; opacity: 0.3;}
.table-progress-item.active{opacity: 1;}
